import React from 'react';
import '../App.css';
import { Button } from './Button.js';
import './HeroSection.css';


function HeroSection() {
	// <video src='/videos/video-1.mp4' autoPlay loop muted />
	// <p>Shows, weddings, parties, and all events requiring a sound system.</p>
	return (
		<div className='hero-container'>
			<h1>Soundtrade</h1>
			<h2>Sound Reinforcement for Live Events</h2>
			<div className="hero-btns">
				<Button
					className='btns'
					buttonStyle='btn--outline'
					buttonSize='btn--large'
					destination='/book'
				>
					BOOK EVENT
				</Button>
			</div>
			<div className='spacer'></div>
			<p> Hire us for your next event! Shows, weddings, meetings, parties, and more!</p>
		</div>
	)
}

export default HeroSection;
