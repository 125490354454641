import React from 'react';
import '../App.css';
import './BookingForm.css';
import './Button.css';
import { Button } from './Button.js';
import Form, {
	Input,
	Select,
	FormButton,
	SwitchList,
	Row,
	TextArea,
	FieldGroup,
	Radio
} from 'react-form-component';

var bookingDescription = `
		Please describe your event. Make note of any special needs. Your system will be ready to go at your event start time.  We will also like to perform a sound check with your team if possible.  If there's anything you're unhappy with during the event, just let one of our team members know.
		`

function BookingForm() {
	return (
		<>
		<div className='booking-room'></div>
		<div className='booking-section'>
			<h1>-BOOK NOW-</h1>
			<h2>Let us take care of the audio for your  next event</h2>
			<br />
			<p>{bookingDescription}</p>
			<p><br /></p>
			<Form
				className='booking-form'
				fields={['date', 'startTime', 'stopTime', 'contactName', 'email', 'event', 'addons']}
				mandatory={['date', 'startTime', 'stopTime', 'contactName', 'email', 'event']}
			>
				<h2>Contact Info</h2>
				<p>Please provide the contact information for the best person to get in touch with about this event</p>
				<Row mobile>
					<Input
						name='contactName'
						label='Name'
					/>
					<Input
						name='email'
						type='email'
						label='E-mail'
					/>
					<Input
						name='contactPhone'
						type='tel'
						label='Phone Number'
					/>
				</Row>
				<h2>Date of Event</h2>
				<p>When will the event be taking place?<br />_</p>
				<Row mobile>
					<Input
						name='date'
						type='date'
						label='Event Date'
					/>
					<Input
						name='startTime'
						type='time'
						label='Event Start Time'
					/>
					<Input
						name='endTime'
						type='time'
						label='Event End Time'
					/>
				</Row>
				<h2>Location</h2>
				<p>Describe where the event will be taking place, and what type of venue it is being held in</p>
				<Radio
					className=""
					help=""
					name="interior"
					options={[
					  'Indoor',
					  'Outdoor']}
				/>
				<Input
					name='terrain'
					label='Describe the environment/space'
				/>
				<Row>
					<Input
						name='address'
						label='Address of Event'
					/>
					<Input
						name='city'
						label='City of Event'
					/>
					<Input
						name='state'
						label='State of Event'
					/>
				</Row>
				<h2>Microphones</h2>
				<p>How many microphones will you need? If there are multiple presenters on stage at a time, each presenter will need a mic.<br />_</p>
				<Row>
					<Select
						name='wirelessMicCount'
						label='Wireless Handheld Mics'
						placeholder='0'
						initialValue='2'
						options={['1','2','3','4','5+']}
					/>
					<Select
						name='wiredMicCount'
						label='Wired Mics'
						placeholder='0'
						initialValue='0'
						options={['1','2','3','4','5+']}
					/>
				</Row>
				<TextArea
					className=""
					help="Help text"
					initialValue=""
					label="Describe your event.  Who will be on stage?  What will they be presenting? Will there be any music? Live or played back from a device?"
					min={0}
					name="eventDescription"
					placeholder=""
					prefix=""
					rows={5}
					suffix=""
				/>
				<FormButton
					onClick={fields => console.log(fields)}
				>Submit</FormButton>
			</Form>
		</div>
		<div className='booking-room'></div>
		</>
	)
}

export default BookingForm;
