import React from 'react';
import { Button } from '../globalStyles';
import { AiFillThunderbolt } from 'react-icons/ai';
import { GiCrystalBars } from 'react-icons/gi';
import { GiCutDiamond, GiRock } from 'react-icons/gi';
import { GiFloatingCrystal } from 'react-icons/gi';
import { TfiMicrophoneAlt } from "react-icons/tfi";
import { MdSpeaker } from "react-icons/md";
import { IconContext } from 'react-icons/lib';
import { PiGuitarDuotone } from "react-icons/pi";
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature,
} from './Pricing.elements';


function features(text_array) {
	var feature_elements = []
	for (var i = 0; i < text_array.length; i++) {
		feature_elements.push((<PricingCardFeature>{text_array[i]}</PricingCardFeature>))
	}
	return (
		<PricingCardFeatures>{feature_elements}</PricingCardFeatures>
	);
}

/*
cardinfo is defined as:


type cardinfo {
	route string
	title string
	icon htmlelement
	price_subtitle string
	price string
	ftures []<PricingCardFeature>
	button_text string
}

*/

function tierCard(cardinfo) {
	return (
            <PricingCard to={cardinfo.route}>
              <PricingCardInfo>
                <PricingCardIcon>
					{cardinfo.icon}
                </PricingCardIcon>
                <PricingCardPlan>{cardinfo.title}</PricingCardPlan>
                <PricingCardLength>{cardinfo.price_subtitle}</PricingCardLength>
                <PricingCardCost>{cardinfo.price}</PricingCardCost>
                <PricingCardFeatures>
					{cardinfo.ftures}
                </PricingCardFeatures>
                <Button primary>{cardinfo.button_text}</Button>
              </PricingCardInfo>
            </PricingCard>
	);
}


const Pricing = () => {
	return (
		<IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
			<PricingSection>
				<PricingWrapper>
					<PricingHeading>Events of all sizes</PricingHeading>
					<PricingContainer>
						{tierCard({
								route: "/book",
								title: "Small Event",
								icon: (<TfiMicrophoneAlt />),
								price_subtitle: 'Starting at',
								price: '$599',
								ftures: features([
									'2 Loudspeakers', 
									'1 wireless handheld mic',
									'Music of your choice']),
								button_text: 'Book Now'})}
						{tierCard({
								route: "/book",
								title: "Medium Event",
								icon: (<MdSpeaker />),
								price_subtitle: 'Starting at',
								price: '$849',
								ftures: features([
									'2 x Loudspeakers', 
									'2 x wireless handheld mics',
									'1 x Podium or Headset Mic',
									'Music of your choice']),
								button_text: 'Book Now'})}
						{tierCard({
								route: "/book",
								title: "Small Band",
								icon: (<PiGuitarDuotone />),
								price_subtitle: 'Starting at',
								price: '$1499',
								ftures: features([
									'2 x Loudspeakers', 
									'1 x Active DI',
									'1 x Passive DI',
									'2 x Wired Vocal Mics',
									'2 x wireless handheld mics',
									'Music of your choice']),
								button_text: 'Book Now'})}
					</PricingContainer>
				</PricingWrapper>
			</PricingSection>
		</IconContext.Provider>
	);
}


export default Pricing

