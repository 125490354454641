import React from 'react';
import { useEffect } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Pricing from '../Pricing';
import ReactGA from 'react-ga4';

function Home() {
	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
		window.scrollTo(0, 0);
	});

	return (
			<>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
				<link href="https://fonts.googleapis.com/css2?family=League+Gothic&display=swap" rel="stylesheet" />
				<HeroSection />
				<Pricing />
			</>
	)
}

export default Home;
