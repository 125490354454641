import React from 'react';
import { useEffect } from 'react';
import '../../App.css';
import BookingForm from '../BookingForm';
import ReactGA from 'react-ga4';

function Book() {
	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: "/book", title: "Book" });
		window.scrollTo(0, 0);
	});

	return (
		<>
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
			<link href="https://fonts.googleapis.com/css2?family=League+Gothic&display=swap" rel="stylesheet" />
			<BookingForm />
		</>
	);
}

export default Book;
