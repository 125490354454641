import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from './components/pages/Home';
import Book from './components/pages/Book';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { FormThemeProvider } from 'react-form-component';

ReactGA.initialize('G-0WL8ZZMSF0')

function App() {

	useEffect(() => {
		document.title = 'SoundTrade';
	})

  return (
	  <FormThemeProvider theme={{ colors: { accent: 'blue' }, sizes: { inputHeight: 30 } }}>
	  <BrowserRouter>
	  	<Navbar />
		  <Routes>
	  		<Route path="/" exact element={<Home />} />
	  		<Route path="/book" exact element={<Book />} />
		  </Routes>
	  </BrowserRouter>
	  </FormThemeProvider>
  );
}

export default App;
